import validationError from "@/helpers/display-error";
import router from "@/router";
import AdminService, {
  CreateOrganizationPayload,
  OrganizationMe
} from "@/services/admin.service.ts";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

interface UseMyOrganizationState {
  loading: boolean;
  organizations: OrganizationMe[];
  showPopup: boolean;
  validation: any;
}
const useMyOrganization = () => {
  const state = reactive<UseMyOrganizationState>({
    loading: true,
    organizations: [],
    showPopup: false,
    validation: {}
  });

  const fetchListOrganization = async () => {
    state.loading = true;
    const data = await AdminService.getOrganizationMe();
    if (data) {
      state.organizations = data;
    }
    state.loading = false;
  };

  const createOrganization = async (
    newOrganization: CreateOrganizationPayload
  ) => {
    state.validation = {};
    const { status, validation } = await AdminService.createOrganization(
      newOrganization
    );
    if (status === 201) {
      return true;
    } else {
      const validate = await validationError(validation);
      if (validate.type === "array") {
        state.validation = validate.validation;
      }
      return validate;
    }
  };

  const chooseOrganization = async (organization: OrganizationMe) => {
    const { apiKey, apiSecret } = organization;
    const res = await AdminService.loginWithOrganization({ apiKey, apiSecret });
    if (res) {
      router.push({ name: "room" });
    }
  };

  const togglePopup = () => {
    state.showPopup = !state.showPopup;
  };

  onMounted(() => {
    fetchListOrganization();
  });
  return {
    ...toRefs(state),
    chooseOrganization,
    togglePopup,
    createOrganization
  };
};
export default useMyOrganization;
