<template>
  <div>
    <div class="d-flex justify-content-between align-items-center p-3">
      <h1 class="font-weight-bold">My Organizations</h1>
      <a-button
        v-if="user.role === 'superadmin' || !user.organizationId"
        type="primary"
        @click="togglePopup"
        >Create</a-button
      >
    </div>
    <template v-if="organizations.length && !loading">
      <div
        v-for="organization in organizations"
        :key="organization.id"
        class="organize-list"
      >
        <div class="d-flex align-items-center">
          <a-avatar :src="organization.favicon"></a-avatar>
          <div class="ml-3">{{ organization.name }}</div>
        </div>
        <div>
          <a-button type="default" @click="chooseOrganization(organization)"
            >Choose</a-button
          >
        </div>
      </div>
    </template>
    <a-modal
      :visible="showPopup"
      title="Create Organization"
      :footer="null"
      @cancel="togglePopup"
    >
      <organization-editor
        :is-create="true"
        :validation="validation"
        @submit-organization="createMyOrganization"
      />
    </a-modal>
  </div>
</template>

<script>
import useMyOrganization from "@/composables/use-my-organization";
import OrganizationEditor from "@/components/OrganizationEditor";
import { get } from "@/store/pathify-helper";
export default {
  name: "MyOrganization",
  components: { OrganizationEditor },
  setup(props, context) {
    const user = get(context, "auth/user");
    const {
      loading,
      organizations,
      chooseOrganization,
      createOrganization,
      togglePopup,
      showPopup,
      validation
    } = useMyOrganization();
    return {
      user,
      // useMyOrganization
      loading,
      organizations,
      chooseOrganization,
      createOrganization,
      togglePopup,
      showPopup,
      validation
    };
  },
  methods: {
    async createMyOrganization(organization) {
      const res = await this.createOrganization(organization);
      if (res === true) {
        this.$message.success("Created Success");
        this.togglePopup();
      } else {
        this.$message.error("Fail");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.organize-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 4px;
  border-bottom: 1px solid #ececec;
}
</style>